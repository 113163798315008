<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Edit owner</h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/loyalty/owners/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                All Owners
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="submitOwner"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  Edit Owner
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <div class="form-group row">
                    <label class="col-3">Name:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.name"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Company:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.company"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner company"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Address:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.address"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner address"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Oib:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.oib"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner oib"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Email:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.email"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner email"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Contact person:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.contactPerson"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner contact person"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Contact phone:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.contactPhone"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner contact phone"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Notification email:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.notificationEmail"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner contact notification email"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Url:</label>
                    <div class="col-9">
                      <input
                        v-model="owner.url"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="Enter owner web address"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  name: 'LoyaltyOwnerEdit',
  data() {
    return {
      owner: {
        name: null,
        company: null,
        address: null,
        oib: null,
        email: null,
        contactPerson: null,
        notificationEmail: null,
        url: null,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Edit Owner', route: '' },
    ])

    this.getOwner(
      'https://loyalty.vendingtycoon.eu/api/v1/owners/',
      this.$route.params.id
    ).then(({ data }) => {
      this.owner.name = data.name
      this.owner.company = data.company
      this.owner.address = data.address
      this.owner.oib = data.oib
      this.owner.email = data.email
      this.owner.contactPerson = data.contactPerson
      this.owner.contactPhone = data.contactPhone
      this.owner.notificationEmail = data.notificationEmail
      this.owner.url = data.url
    })
  },
  methods: {
    async getOwner(owner, uuid) {
      try {
        return axios.get(owner + uuid)
      } catch (e) {
        throw new Error(e)
      }
    },
    async patchOwner(uuid, owner) {
      try {
        return axios.patch(
          'https://loyalty.vendingtycoon.eu/api/v1/owners/' + uuid,
          owner,
          {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          }
        )
      } catch (e) {
        throw new Error(e)
      }
    },
    submitOwner() {
      this.patchOwner(this.$route.params.id, this.owner)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Owner successful updated in database',
          })

          setTimeout(() => {
            this.$router.push({ name: 'loyalty-owners-list' })
          }, 500)
        })
        .catch((e) => {
          throw new Error(e)
        })
    },
  },
}
</script>
